import classnames from 'classnames'
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'

import Icon from '../utils/Icon'

export default function RulesModal({ show, settings, isDarkTheme, onHide, isMobile = false }) {
  if (window && show) {
    const body = window.document.querySelector("body");

    if (!isDarkTheme) {
      body?.classList?.add("light-mode");
      body?.classList?.remove("dark-mode");
    } else {
      body?.classList?.add("dark-mode");
      body?.classList?.remove("light-mode");
    }
  }

  useEffect(() => {
    if (show) {
      if (typeof fbq !== 'undefined') {
        fbq('track', 'View Content');
      }
    }
  }, []);

  return (
    <Modal show={show} onHide={onHide} animation={false} dialogClassName={isMobile ? 'modal-fullscreen' : 'modal-dialog-centered modal-dialog-scrollable'}>
      <Modal.Header className={classnames(['pt-2 pb-2', { 'bg-dark': isDarkTheme }])}>
        <Modal.Title className={classnames({ 'text-white': isDarkTheme })}>
          {settings.titulo_regras || 'Regras'}
        </Modal.Title>
        <Button variant={isDarkTheme ? 'dark' : 'light'} className={classnames(['text-right mt-1', { 'text-white': isDarkTheme }])} onClick={onHide}>
          <Icon name="times" />
        </Button>
      </Modal.Header>
      <Modal.Body className={classnames(['scroll p-3', { 'text-white': isDarkTheme }])} dangerouslySetInnerHTML={{ __html: settings.regras || '' }}>
      </Modal.Body>
    </Modal>
  )
}
